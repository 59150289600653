import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { DoubleBookingData } from './doubleBooking.types';

const initialState: DoubleBookingData = {
  ui: {
    doubleBookingOpened: false,
  },
};

export const doubleBookingSlice = createSlice({
  name: 'doubleBooking',
  initialState,
  reducers: {
    openDoubleBooking: (state) => {
      state.ui.doubleBookingOpened = true;
    },
    closeDoubleBooking: (state) => {
      state.ui.doubleBookingOpened = false;
    },
  },
});

export const { openDoubleBooking, closeDoubleBooking } =
  doubleBookingSlice.actions;

export const selectDoubleBooking = (state: RootState) => state.doubleBooking;

export const doubleBookingReducer = doubleBookingSlice.reducer;
