import { useAppDispatch } from 'app/hooks';
import { closeDoubleBooking } from '../doubleBooking.slice';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import styles from './doubleBookingBody.module.scss';

const bookingLinks = document.getElementById('booking-links');
const linkWith = bookingLinks?.dataset.bookingLinkWith;
const linkWithout = bookingLinks?.dataset.bookingLinkWithout;

export const DoubleBookingBody = (): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeDoubleBooking())}
      />
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />

      <h2 className={styles.title}>Бронирование</h2>

      <div className={styles.items}>
        <p className={styles.item}>
          <a
            className={styles.with}
            href={linkWith}
            target="_blank"
            rel="noreferrer"
          >
            С питанием
          </a>
        </p>

        <p className={styles.item}>
          <a
            className={styles.without}
            href={linkWithout}
            target="_blank"
            rel="noreferrer"
          >
            Без питания
          </a>
        </p>
      </div>
    </div>
  );
};
