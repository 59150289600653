import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { commonReducer } from 'features/common/common.slice';
import { callbackReducer } from 'features/callback/callback.slice';
import { calculationReducer } from 'features/calculation/calculation.slice';
import { serviceReducer } from 'features/service/service.slice';
import { reviewReducer } from 'features/review/review.slice';
import { addReviewReducer } from 'features/addReview/addReview.slice';
import { questionReducer } from 'features/question/question.slice';
import { quizReducer } from 'features/quiz/quiz.slice';
import { doubleBookingReducer } from 'features/doubleBooking/doubleBooking.slice';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    callback: callbackReducer,
    calculation: calculationReducer,
    service: serviceReducer,
    review: reviewReducer,
    addReview: addReviewReducer,
    question: questionReducer,
    quiz: quizReducer,
    doubleBooking: doubleBookingReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
