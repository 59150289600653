import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import { Policy } from 'components/elements/policy/Policy';
import { Input } from 'components/forms/input/Input';
import { openTextModal, openThanks } from 'features/common/common.slice';
import { FormEventHandler } from 'react';
import {
  clearCallback,
  closeCallback,
  selectCallback,
  setCallbackName,
  setCallbackPhone,
  toggleCallbackPolicy,
} from '../callback.slice';
import styles from './callbackBody.module.scss';

declare const ym: any;
declare const gtag: any;

export const CallbackBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const callback = useAppSelector(selectCallback);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    ym(87397023, 'reachGoal', 'kick');
    gtag('event', 'submit', { event_category: 'form' });

    dispatch(closeCallback());
    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Обратный звонок',
        name: callback.name,
        phone: callback.phone,
      }),
    });

    dispatch(clearCallback());
  };

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeCallback())}
      />
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />

      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Обратный звонок</h2>
        <Input
          className={styles.name}
          placeholder="Имя"
          value={callback.name}
          onChange={(event) => dispatch(setCallbackName(event.target.value))}
        />
        <Input
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="^\+?[0-9()\- ]+"
          required
          value={callback.phone}
          onChange={(event) => dispatch(setCallbackPhone(event.target.value))}
        />
        <Policy
          className={styles.policy}
          checked={callback.policyChecked}
          onChange={() => dispatch(toggleCallbackPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />
        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
};
