import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Policy } from 'components/elements/policy/Policy';
import { Input } from 'components/forms/input/Input';
import { openTextModal, openThanks } from 'features/common/common.slice';
import { FormEventHandler } from 'react';
import {
  clearQuestion,
  selectQuestion,
  setQuestionName,
  setQuestionPhone,
  toggleQuestionPolicy,
} from './question.slice';
import styles from './question.module.scss';

declare const ym: any;
declare const gtag: any;

export const Question = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const question = useAppSelector(selectQuestion);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    ym(87397023, 'reachGoal', 'kick');
    gtag('event', 'submit', { event_category: 'form' });

    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Вопрос',
        name: question.name,
        phone: question.phone,
      }),
    });

    dispatch(clearQuestion());
  };

  return (
    <form className={styles.module} onSubmit={handleSubmit}>
      <Input
        className={styles.name}
        placeholder="Имя"
        value={question.name}
        onChange={(event) => dispatch(setQuestionName(event.target.value))}
      />
      <Input
        className={styles.phone}
        placeholder="Телефон"
        type="tel"
        pattern="^\+?[0-9()\- ]+"
        required
        value={question.phone}
        onChange={(event) => dispatch(setQuestionPhone(event.target.value))}
      />
      <button className={styles.button}>Заказать звонок</button>
      <Policy
        className={styles.policy}
        checked={question.policyChecked}
        onChange={() => dispatch(toggleQuestionPolicy())}
        onClick={() => dispatch(openTextModal('policy'))}
        required
        inverted
      />
    </form>
  );
};
