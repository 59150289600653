import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Babies } from 'components/elements/babies/Babies';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import { Policy } from 'components/elements/policy/Policy';
import { DatePicker } from 'components/forms/DatePicker/DatePicker';
import { Input } from 'components/forms/input/Input';
import { Select } from 'components/forms/select/Select';
import { Textarea } from 'components/forms/textarea/Textarea';
import { openTextModal, openThanks } from 'features/common/common.slice';
import { FormEventHandler, MouseEventHandler } from 'react';
import { batch } from 'react-redux';
import { getAdultOptions, getBabyOptions } from 'utils/helpers/booking.helpers';
import {
  changeCalculationAdults,
  changeCalculationBabies,
  clearCalculation,
  closeCalculation,
  closeCalculationArrival,
  closeCalculationBabies,
  closeCalculationDeparture,
  openCalculationArrival,
  openCalculationBabies,
  openCalculationDeparture,
  selectCalculation,
  setCalculationArrival,
  setCalculationBaby,
  setCalculationComment,
  setCalculationDeparture,
  setCalculationEmail,
  setCalculationName,
  setCalculationPhone,
  toggleCalculationPolicy,
} from '../calculation.slice';
import styles from './calculationBody.module.scss';

declare const ym: any;
declare const gtag: any;

export const CalculationBody = () => {
  const dispatch = useAppDispatch();
  const {
    room,
    arrival,
    departure,
    adults,
    babies,
    name,
    phone,
    email,
    comment,
    policyChecked,
    type,
    ui,
  } = useAppSelector(selectCalculation);

  const types = {
    booking: 'Бронирование',
    calculation: 'Расчет стоимости',
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (arrival === null) {
      dispatch(openCalculationArrival());
      return;
    }
    if (departure === null) {
      dispatch(openCalculationDeparture());
      return;
    }

    ym(87397023, 'reachGoal', 'kick');
    gtag('event', 'submit', { event_category: 'form' });

    dispatch(closeCalculation());
    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: types[type],
        room,
        arrival: new Date(arrival).toLocaleDateString(),
        departure: new Date(departure).toLocaleDateString(),
        adults,
        babies,
        name,
        phone,
        email,
        comment,
      }),
    });

    dispatch(clearCalculation());
  };

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (arrival === null) {
      dispatch(openCalculationArrival());
      event.preventDefault();
      return;
    }
    if (departure === null) {
      dispatch(openCalculationDeparture());
      event.preventDefault();
      return;
    }
  };

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeCalculation())}
      />
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />

      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>{types[type]}</h2>
        <DatePicker
          className={styles.arrival}
          name="Дата заезда"
          isOpen={ui.arrivalOpened}
          disabledDaysBefore={new Date()}
          onClick={() =>
            dispatch(
              ui.arrivalOpened
                ? closeCalculationArrival()
                : openCalculationArrival()
            )
          }
          onOutsideClick={() => dispatch(closeCalculationArrival())}
          onDayClick={(date) => {
            batch(() => {
              dispatch(setCalculationArrival(date.valueOf()));
              dispatch(closeCalculationArrival());
            });
          }}
          value={arrival}
        />
        <DatePicker
          className={styles.arrival}
          name="Дата отъезда"
          isOpen={ui.departureOpened}
          disabledDaysBefore={arrival ? new Date(arrival) : new Date()}
          onClick={() =>
            dispatch(
              ui.departureOpened
                ? closeCalculationDeparture()
                : openCalculationDeparture()
            )
          }
          onOutsideClick={() => dispatch(closeCalculationDeparture())}
          onDayClick={(date) => {
            batch(() => {
              dispatch(setCalculationDeparture(date.valueOf()));
              dispatch(closeCalculationDeparture());
            });
          }}
          value={departure}
        />
        <Select
          className={styles.adults}
          title="Кол-во взрослых"
          paddingLeft={150}
          options={getAdultOptions()}
          onChange={(event) =>
            dispatch(changeCalculationAdults(+event.target.value))
          }
          value={adults}
        />
        <Select
          className={styles.babies}
          title="Кол-во детей"
          paddingLeft={120}
          options={getBabyOptions()}
          onChange={(event) =>
            dispatch(changeCalculationBabies(+event.target.value))
          }
          value={babies.length}
        />
        {babies.length > 0 && (
          <Babies
            className={styles.babyList}
            isOpen={ui.babiesOpened}
            onClick={() =>
              dispatch(
                ui.babiesOpened
                  ? closeCalculationBabies()
                  : openCalculationBabies()
              )
            }
            onOutsideOrConfirmClick={() => dispatch(closeCalculationBabies())}
            onSetBaby={(idx, age) => dispatch(setCalculationBaby({ idx, age }))}
            babies={babies}
          />
        )}
        <Input
          className={styles.name}
          placeholder="Ф.И.О."
          value={name}
          onChange={(event) => dispatch(setCalculationName(event.target.value))}
        />
        <Input
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="^\+?[0-9()\- ]+"
          required
          value={phone}
          onChange={(event) =>
            dispatch(setCalculationPhone(event.target.value))
          }
        />
        <Input
          className={styles.email}
          placeholder="Эл. почта"
          type="email"
          required
          value={email}
          onChange={(event) =>
            dispatch(setCalculationEmail(event.target.value))
          }
        />
        <Textarea
          className={styles.comment}
          placeholder="Комментарий"
          required
          value={comment}
          onChange={(event) =>
            dispatch(setCalculationComment(event.target.value))
          }
        />
        <Policy
          className={styles.policy}
          checked={policyChecked}
          onChange={() => dispatch(toggleCalculationPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />
        <button className={styles.button} onClick={handleConfirm}>
          Отправить
        </button>
      </form>
    </div>
  );
};
